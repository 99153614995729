const en = {
    add_company: 'Add company',
    businessarea: 'Sector',
    choose_social: 'Choose social',
    company: 'Company',
    choose_company: 'Choose company',
    manage_company: 'Manage company',
    change_company: 'Change company',
    cover_photo: 'Cover photo',
    add_cover_photo: 'Add cover photo',
    image_recommended: 'Image strongly recommended',
    company_headquarters: 'Company headquarters',
    headquarters: 'Headquarters',
    social_network: 'Social Network',
    services_employer: 'Employee Services',
    areas_expertise: 'Areas of expertise',
    look_for_tech_and_creative_talents: 'Look for tech and creative talents',
    search_company: 'Search Company',
    admin: 'Administrator',
    employee: 'Employee',
    human_resource: 'Human Resources',
    trainer: 'Trainer',
    talents: 'Talents',
    offers: 'Offers',
    team: 'Team',
    delivery: 'Delivery',
    no_requests_for_your_company: 'There are no new requests for your company',
    add_company_message:
        'The business manager will receive a match notification and will be able to approve or reject it',
    upload_title: 'Drag CVs here or',
    upload_title_part_2: 'browse',
    upload_allowed_formats: 'Supported formats: PDF, DOC, DOCX',
    start_upload: 'Start uploading',
    upload_search: 'Search file name',
    start_date: 'Start date',
    end_date: 'End date',
    uploading: 'Loading...',
    upload_completed: 'Upload completed',
    to_finish: 'when finished',
    upload_of: 'Uploading of',
    processed: 'Completed',
    state: 'State',
    errors: 'Errors',
    file_added_message: 'You have uploaded',
    file_added_message_1: 'curriculum, start loading now',
    working_in: 'Working in',
    file_required: 'At least one file must be uploaded to begin uploading',
    employer_requests: 'Requests',
    employer_list: 'Employee list',
    configuration: 'Configuration',
    integration: 'API Key',
    request_to_approve: 'requests to approve',
    change_role: 'Change role',
    remove_employer: 'Forget user',
    assign_role: 'Assign role',
    company_profile: 'Company profile',
    company_settings: 'Company settings',
    open_hr: 'Open HR',
    vat: 'VAT number',
    status: 'Status',
    date: 'Date',
    who: 'Who',
    what: 'What',
    where: 'Where',
    how: 'How',
    remote_or_on_site: 'Hybrid, remote or on-site?',
    opportunity_type: 'Opportunity type',
    recruit_talent_cta: 'Do you want to contact the selected candidates for recruiting?',
    confirm: 'Confirm',
    talent_result_message:
        'You found {yourDb} talents in your database and {ourDb} talents in the WH database',
    profile_saved_by: 'Profile saved by {count} companies',
    order_by: 'Order by',
    active: 'Active',
    passive: 'Passive',
    activate_openhr: 'Activate Open HR',
    activate_service: 'Activate the service',
    connect_with_providers: 'Connect the providers you work with',
    extend_talent_search: 'Extend talent searches',
    passage: 'Step {step} of {total}',
    openhr_activation: 'Open HR Activation',
    confirm_company_data: 'Confirm your company data',
    confirm_company_data_subtitle:
        'Look at your company data and make sure it is correct',
    company_name: 'Company name',
    registered_office: 'Registered office',
    sector: 'Sector',
    your_confirmation: 'Your confirmations',
    profiling: 'Profiling Open Wh!',
    profiling_subtitle:
        'You consent to the profiling processing of your personal and particular data, acquired Wh! in the context of the Open HR service, together with other data, to deduce products/services of potential interest to you as well as your preferences, tastes and behaviour?',
    do_not_accept: 'I do not accept',
    openhr_service: 'OpenHR Service',
    acknowledgment: 'Acknowledged',
    contract_cta:
        'To sign the contract enter your personal password Wh! and the confirmation code you received to your verified email',
    openhr_profiling: 'Open HR Profiling',
    openhr_privacy: 'Privacy consent',
    privacy_policy_url: 'Privacy Policy URL',
    api_key: 'API Key',
    documentation: 'Documentation',
    generate_api_key_text: 'Generate an API key to use the WH APIs',
    api_key_docs_link: 'See API Key documentation',
    generate_api_key: 'Generate API Key',
    copy_api_key: 'Copy API Key',
    copy_company_id: 'Copy Company ID',
    company_id_copied: 'Company ID copied',
    confirmation_code: 'Confirmation code',
    restore_code: 'Recover code',
    openhr_search:
        'Enter the Company name or VAT number preceded by the country code (e.g. IT12345678910)',
    db_visibility: 'Grant visibility of my talent database externally',
    db_visibility_subtitle:
        'We remind you that only visibility of the macrodata in your database will be granted',
    all_db_visible: 'Visibility of entire database',
    rejected_profiles: 'Visibility only rejected profiles',
    professionals: 'professionals',
    connect: 'Connect',
    best_plan_for_your: 'What is the best plan for you?',
    free_plan: 'Take a free trial. No credit card needed.',
    select_later: 'Choose later',
    subscription_plan: {
        option_popular: 'Most popular option',
        free: {
            title: 'FREE',
            month_employee: 'by month & employee',
            basic: 'Basic',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Main functions',
            continue_free: 'Continue with the free plan',
        },
        professional: {
            from: 'from',
            month_employee: 'by month & employee',
            professional: 'Professional',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'All Basic features plus:',
            calculate_cost: 'Calculate costs',
        },
        enterprise: {
            title: 'Price on request',
            enterprise: 'Enterprise',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'All Professional features plus:',
            contact_team: 'Contact the sales team',
        },
        calculate_prices: 'Calculate costs',
        company_dimension: 'Company size',
        billing_cycle: 'Billing cycle',
        select_plan: 'Choose plan',
        open_job_positions: 'Published job positions',
        open_hr: 'Open HR',
        price_information: 'Price information',
        enterprise_plan: 'Enterprise Plan',
        recruiting: 'Recruiting',
        monthly_payment: 'Monthly payment',
        yearly: 'Annual',
        buy: 'Buy',
    },
    not_working_here_anymore: "He doesn't work here anymore",
    employee_removed: 'Employee successfully removed',
    delete_employee: 'Are you sure you want to remove this employee?',
    talent_search_detail: {
        contact_info: 'Contact details of the reference account',
        talent_score: 'Talent score',
        suggestions: 'Suggestions',
        inferences_map: 'Inferences map',
        soft_skills: 'Soft Skills',
        profile_matching: 'Profile matching your search',
        overview: 'Overview',
        video_curricula: 'Video CV',
        top_hard_skills: 'Top hard skills',
        top_soft_skills: 'Top soft skills',
        set_interview: 'Arrange a video interview',
        data_from: 'Data taken from:',
        data_from_info_tooltip:
            'Wh algorithms process the information present in the CV and integrate it with other data, showing you the icons of the analyzed sources (e.g. Curriculum, Linkedin, GitHub). If a source is available at a later time the information will be updated automatically.',
        reliability: 'Reliability',
        personality: 'Personality',
        potential: 'Potential',
        experience: 'Experience',
        leadership: 'Leadership',
        experience_data: 'Experience data',
        sector: 'Sector',
        dimension: 'Dimension',
        companies: 'Companies',
        work_companies: 'Companies where you worked',
        number_working_experience: 'Number of working experiences',
        company_change_frequency: 'Company change frequency',
        companyInfo: 'This profile is managed by the company {company}',
        evaluations: 'Evaluations',
        addEvaluation: 'Add evaluation',
        hard_skills: 'Hard skills',
        professional_qualifications: 'Professional qualification',
        cv: 'cv',
        save_search: {
            title: 'Give your search a name. You can save it and take it back later.',
            search_placeholder: 'Search name',
            title_save_talent: 'Add talents to your search, select them and click save',
        },
        between_and: 'between {from} and {to}',
        every_time: 'every {year} year/s',
        secondary_qualification: 'Secondary qualification',
        training: 'Training',
        typology: 'Typology',
        summary: 'Summary',
        describe_yourself: 'Describe yourself',
        current_salary: 'What is your current salary? (will be verified)',
        desired_salary: 'What is your desired salary?',
        salary: 'Salary',
    },
    request_talent_meeting: {
        title: 'Get to know the candidate with a video interview',
        subtitle:
            'Enter three options for the video call. The candidate can propose other dates.',
        info: 'By clicking on send an email will be sent to the candidate.',
        insert_message: 'Insert a message and link to yours',
        send_request: 'Send and wait for confirmation',
        add_info: 'Add offer details',
        meeting_requested: 'Meeting requested successfully',
    },
    send_confirmation: {
        title: 'Choose the interview date',
        subtitle:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
        send_request: 'Send and wait for confirmation',
    },
    add_offer_detail: {
        title: 'Offer Details',
        configure_new: 'Configure a new offer',
        duplicate: 'Duplicate an existing offer',
        choose_existing: 'Choose an existing offer',
        next: 'Next',
    },
    create_new_offer: {
        title: 'Information (1of2)',
        ads_title_placeholder: 'Advertisement title',
        opportunity_type: 'Opportunity type√†',
        hiring: 'hiring',
        freelance: 'freelance',
        opportunity_features: 'Opportunity Features',
        less_1_year: 'less than 1 year',
        over_1_year: 'over 1 year',
        job_place: 'Place of work activity',
        remote: 'remotely',
        in_office: 'in office',
        mixed: 'mixed',
        job_address: 'Location Information',
    },
    create_new_offer_2: {
        title: 'Information (2of2)',
        activate_chat: 'Do you want to activate the chat service?',
        yes: 'yes',
        no: 'no',
        send_and_wait_confirmation: 'Send and wait for confirmation',
    },
    talent_search_history: {
        title: 'Saved search history',
        search_placeholder: 'Search',
        table: {
            head: {
                name: 'Search name',
                prifle: 'Profile searched for',
                date: 'Date',
                saved: 'Saved',
                conversed: 'Talk',
            },
        },
    },
    draft_candidate: {
        title: 'You are rejecting a candidate, tell us the reasons to improve artificial intelligence algorithms.',
        subtitle:
            'Until we have verified your company details you will not be able to post job adverts.',
        add_valuation: 'Add evaluation',
    },
    in_selections: 'In selection',
    refused: 'Rejected',
    interview_panel: {
        title: 'Interviews',
        second_interview: 'Second interview',
        interviews_history: 'History of interviews',
        interview: 'Interview',
        interview_status: 'Assessment in progress',
    },
    talent_search_filters: {
        contact_info: 'Contact information',
        talent_score: 'Talent score',
        suggestions: 'Suggestions',
        inferences_map: 'Inferences map',
        experience: 'Experiences',
        soft_skills: 'Soft skills',
        evaluations: 'Evaluations',
        experiences: 'Experiences',
        top_skill: 'Top skill',
        resume: 'Summary',
        sharings: 'Shares',
    },
    no_employees: 'There are no employees in your company',
    no_jobAnnouncements: 'You have no ads to view.',
    no_jobAnnouncements_create: 'Start by creating an announcement.',
    company_vat_already_exist: 'A company already exists with this VAT number',
    user_already_in_company:
        'You are already part of this company, enter a different company ID',
    company_not_found: 'This company is not present, please enter a different company ID',
    company_found_request_access: 'We found a company, request access to the company',
    add_service_employer: 'Add employee service',
    add_expertise_area: 'Add area of expertise',
    you_are_asking_to_be_paired: 'You are asking to be paired with a company.',
    file_uploaded_tooltip:
        'Processed files are the uploaded files that Wh processes to be analyzed by Artificial Intelligence algorithms',
    file_completed_tooltip:
        'Wh processed the uploaded files. The files have been indexed and the results appear in your searches according to the processing steps already performed. The process involves several phases, including for example: analysis using Wh AI algorithms, analysis using OpenAI, Enrichment, Inference map, etc.',
    file_errors_tooltip:
        "Wh's algorithms were unable to read the files you uploaded or something went wrong",
    we_are_verifying: 'You almost did it. We are verifying your company data.',
    request_sent_to_company_admin:
        'We have sent your request to the company administrator {companyName}',
    you_have_not_companies: 'You have not companies yet',
    we_are_verifing_your_company_data: 'We are verifying your company {companyName} data',
    we_are_verifying_data:
        'We are verifying your company, you will soon be able to publish your first job advert.',
    add_one_now: 'Add one now',
    until_we_have_verified_your_company:
        'Until we have verified your company data you will not be able to post job adverts',
    you_are_asking_for_a_match:
        'You are asking to be matched with the company {companyName}. The company administrator will receive a match notification and will be able to approve or reject it. Make sure you only apply if you actually work with this company.',
    yes_work_for_this_company: 'Yes, I work for this company',
    wait_for_the_company_administrator_to_approve:
        'Please wait for your company administrator to approve your request to have access to company features.',
    share_offer_page: 'Share offer page',
    share_offer_page_message:
        'Copy and paste this link to your site to share the page with your job offers',
    share_offer_page_jobs: 'Share single offer',
    share_offer_page_jobs_message:
        'Copy and paste this link to share the offer with potential candidates',
    add_company_after_validation:
        'After validation you can choose whether to continue with the free plan or one of our paid plans.',
    confirm_to_work_in_this_company:
        'I confirm that I have the necessary authorizations to operate for this company',
    click_and_confirm_to_work_in_this_company:
        'By clicking on "start" you declare that you work for this company.',
    your_CV: 'Your CV',
    company_CV: 'Your company CV',
    wh_CV: "WH's CV",
    your_employees: 'Your employees',
    company_employees: 'The employees of your company',
    wh_employees: 'WH employees',
    db_search_type: 'Search database type',
    installments: 'Tariff',
    liked_profile: 'Profiles I like',
    my_offers: 'My offers',
    no_jobAnnouncements_applications: 'There are no applications for this job offer',
    teamlist_title: 'Teamlist Title',
    teamlist_subtitle: 'Teamlist Description',
    process_status: {
        completed: 'Processed',
        created: 'Created',
        error: 'Error',
        loaded: 'Loaded',
        processing: 'Processing',
        uploaded: 'Uploaded',
    },
    search_saved: 'Search saved successfully',
    talent_saved: 'Talents added to search successfully',
    process_by: 'Processed by',
    process_by_info:
        "Disclaimer for Wh. All information is published in good faith and for general information purposes only. Wh makes no warranties as to the completeness, reliability and accuracy of this information. Any action which You undertake the information you find on this website (whp.ai), strictly at your own risk. Wh will not be liable for any loss and/or damage in connection with the use of our services. <u>The information contained on this website (whp.ai) were generated through automated artificial intelligence processing with Wh's proprietary models and third-party services (e.g. OpenAI, ESCO). We therefore recommend a human review of the information represented.",
    business_area_public: 'Public Administration',
    business_area_agency: 'Agency',
    business_area_company: 'Company',
    orderby_correspondence:
        'Click here to see the CVs that have a higher match percentage with your search',
    oderby_lastupdate: 'Click here to see the most recent CVs',
    no_approved_companies: 'There are no approved companies',
    no_to_approve_companies: 'There are no companies to approve',
    cv_state: 'State',
    archived: 'Archived',
    in_deletion: 'Deleted',
    cv_archived: 'Archived CV',
    cv_deleted: 'CV Deleted',
    cv_activated: 'CV Restored',
    no_saved_search: "You haven't saved any searches yet",
    cvDetail: {
        active: 'Active',
        search_corresponding_title: 'Matching your search:',
        search_corresponding_from_db: '{count} talents in your database',
        search_corresponding_from_openhr: '{count} talents in OpenHR',
        protected_category: 'Protected category L. 68/99',
        experience_data: 'Experience data',
        companies: 'Companies',
        sectors: 'Sectors',
        company_dimension: 'Company size',
        experience_number: 'Number of work experiences',
        turnover_frequency: 'Frequency of company change',
        other_qualifications: 'Other qualifications',
        top_skill: 'Top skill',
        export_pdf: 'Export PDF',
        like_candidate: 'Add CV to favourites',
        unlike_candidate: 'Remove resume from favorites',
        save_candidate: "Save the candidate's CV",
        remove_candidate: "Remove the candidate's CV from saved profiles",
        meet_candidate: 'Set up a meeting with the candidate',
        visibility_candidate_internal:
            'Visibility of the CV: the CV is NOT visible outside your organisation',
        visibility_candidate_total:
            'Visibility of the CV: the CV is visible outside your organisation',
        archive_cv_candidate: "Archive the candidate's CV",
        delete_cv_candidate: "Delete the candidate's CV",
        activate_cv_candidate: "Activate the candidate's CV",
        all: 'All CVs',
        external: 'OpenHR',
        internal: 'Your company CVs',
        platform: 'WH CVs',
        user_hr: 'Your CVs',
        candidate_work_preference: "Candidate's work preferences",
        candidate_opportunities_type: 'Types of opportunities preferred by the candidate',
        addOtherQualification: 'Add other qualifications',
        addOtherSkill: 'Add other skills',
        add: 'added',
        update: 'updated',
        delete: 'deleted',
        of_company: 'of {company}',
        have: 'has',
        as: 'as',
        field: {
            qualification: 'qualification',
            fullname: 'full name',
            skill: 'competence',
            area: 'area',
            residencecity: 'city of residence',
            sector: 'sector',
            protectedcategory: 'protected category',
            seniority: 'seniority',
        },
        edits_list: 'Edit History',
    },
    overview: 'Overview',
    percentage: 'Percentage',
    skill: 'Expertise',
    keywords: 'Keywords',
    placeholder_keywords: 'Keywords to filter CVs',
    edit_cv_ok:
        "You have correctly edited your CV, if you don't see the changes wait a few seconds and reload the page",
    insert_title_saved_search: 'Insert a name for your saved search',
    check_total_workload: 'Total Workload % must be 100',
    delete_team_list: 'Are you sure you want to delete this teamlist?',
    check_all_qualifications: 'Select a profile for each team member',
    teamlist_step: 'Step',
    teamlist_collaborative: 'COLLABORATIVE TEAMLIST',
    teamlist_in_creation: 'In creation',
    teamlist_active: 'Active',
    teamlist_workload: '% Workload',
    team_list_other_info: 'Add more information',
    team_list_remove: 'Remove from list',
    team_list_add_other: 'Add more team members',
    team_list_profile: 'Profile',
    delete: 'Delete',
    team_list_experience: 'Experience (Years)',
    team_list_activity: 'Activity',
    team_list_rate: 'Daily rate',
    team_list_responsibility: 'Responsibility',
    teamlist_other_info: 'Other information',
    team_list_add_other_mobile: 'Add other members',
    workload: 'Use',
    team_list_experience_between_1: 'experience between',
    team_list_experience_between_2: 'Experience between',
    team_list_employment_equal_to_1: 'employment equal to',
    team_list_employment_equal_to_2: 'Employment equal to',
    check_talent_selected: 'Select a talent for each profile searched',
    team_list_search_profile: 'Select one of the suggested profiles or search for others',
    cvState: {
        active: 'Active',
        archived: 'Archived',
        in_deletion: 'Deleted',
    },
    save_selected_talent: 'You are saving only selected CVs',
    save_all_talent: 'You are saving all the CVs in the list',
    add_cv_to_saved_search: 'Add CVs to a saved search',
    select_saved_search: 'Select a search',
    or_add_new_saved_search: 'Or add a new one',
    anonymized: 'Anonymized',
    hire_in_your_company: 'Hire in your company',
    remove_from_your_company: 'Remove from your company',
    cv_uploaded: 'Uploaded resumes',
    saved_profiles: 'saved profiles',
    saved_job_offers: 'saved offers',
    name_column_subtitle: 'Ratings and descriptive tags',
    qualification_column_subtitle: 'Organisational membership box',
    country_company: 'Where is your company located?',
    us_worker: 'US Worker',
    us_citizenship: 'US Citizenship',
    us_citizenship_status: 'US Citizenship status',
    select_citizenship_status_label: 'Citizenship status',
    specify_citizenship_status_other: 'Please specify',
    citizenshipStatus: {
        green_card: 'Green Card',
        h1bvisa: 'H1B Visa',
        h4visa: 'H4 Visa',
        o1visa: 'O1 Visa',
        other: 'Other',
        s1Visa: 'S1 Visa',
        t1Visa: 'T1 Visa',
        uscitizen: 'US Citizen',
    },
    over18: 'Over the age of 18',
    generic_questions: {
        title: 'Generic Questions',
        already_applied: 'Have you ever applied to/worked for {companyName} before?',
        already_applied_info: 'Please explain',
        other_people_workers:
            'Do you have any friends, relatives, or acquaintances working for {companyName}?',
        other_people_workers_info: 'Name and relationship with that person',
        have_transportation: 'If hired, would you have transportation to/from work?',
        submit_drug_test:
            'If hired, are you willing to submit to and pass a controlled substance test?',
        work_in_high_school: 'Did you work in high school?',
        work_in_high_school_info: 'What did you do then?',
        is_volunteer: 'Do you act as a volunteer for any organizations?',
        is_volunteer_info: 'What organizations do you work with and in what capacity?',
        has_pets: 'Do you have any pets?',
        has_pets_info: 'What pet do you have?',
        pets_count: 'How many pets?',
        pets: {
            dog: 'Dog',
            cat: 'Cat',
            fish: 'Fish',
            bird: 'Bird',
            gerbil: 'Gerbil',
            snake: 'Snake',
            others: 'Others',
        },
        currently_employed: 'Are you currently employed?',
        currently_employed_year:
            'Have you been with your current company for more than 2 years?',
    },
    other_info: {
        address_line1: 'Street address',
        address_line2: 'Address line 2',
        city: 'City',
        state: 'State / Province / Region',
        postal_code: 'ZIP / Postal Code',
        country: 'Country',
        relevant_experience: 'Relevant Experience',
        title: 'Other Informations',
        available_work_days_hours: 'What days and hours are you available to work?',
        hiring_start_date: 'If hired, on what date can you start working?',
        work_on_weekend: 'Can you work on weekends?',
        work_evenings: 'Can you work evenings?',
        work_overtime: 'Are you available to work overtime?',
        other_experience: 'Other experience',
        what_other_work_experience:
            'What other work experience do you have? Please check all that apply*',
        experience_i_t:
            '10 or more years of experience working with IT systems, capabilities, platforms or processes',
        experience_project_manager: '3 or more years of project management experience',
        experience_leadership: '3 or more years of experience in a leadership role',
        experience_core_networking_technologies:
            '5 or more years of experience with Core Networking technologies',
        experience_learge_scale_networking_systems:
            'Prior IT experience implementing large scale Networking systems or other large program implementations',
        experience_managing_partner:
            'Any experience managing partner and vendor relationships to achieve business outcomes',
        experience_customer_service: 'Any experience in customer service',
        experience_firewall: '2 or more years of Firewall experience',
        experience_virtualization: '2 or more years of Virtualization experience',
        experience_office365: '1 or more years of experience with Office 365',
        experience_azure: '1 or more years of experience with Azure',
        military: 'Military',
        military_branch: 'Branch',
        military_rank: 'Rank',
        military_years: 'Total years of service',
        military_skills: 'Skills / duties',
        military_details: 'Related details',
        vocational_school: 'Vocational School',
        vocational_school_name: 'Vocational School Name',
        vocational_school_address_line1: 'Street Address',
        vocational_school_address_line2: 'Address Line 2',
        vocational_school_city: 'City',
        vocational_school_state: 'State / Province / Region',
        vocational_school_postal_code: 'ZIP / Postal Code',
        vocational_school_country: 'Country',
        vocational_school_years: 'Number of years completed',
        vocational_school_graduate: 'Did you graduate?',
        vocational_school_diploma: 'Degree/diploma earned',
        university_school: 'College / University',
        university_school_name: 'College / University Name',
        university_school_address_line1: 'Street Address',
        university_school_address_line2: 'Address Line 2',
        university_school_city: 'City',
        university_school_state: 'State / Province / Region',
        university_school_postal_code: 'ZIP / Postal Code',
        university_school_country: 'Country',
        university_school_years: 'Number of years completed',
        university_school_graduate: 'Did you graduate?',
        university_school_diploma: 'Degree/diploma earned',
        certifications: 'Certifications',
        certification_name: 'Certification',
        certification_issuing_organization: 'Issuing Organization',
        certification_number: 'Number/ID',
        certification_date: 'Issue Date',
        additional_certification: 'Additional Certification',
        additional_certification_name: 'Certification',
        additional_certification_issuing_organization: 'Issuing Organization',
        additional_certification_number: 'Number/ID',
        additional_certification_date: 'Issue Date',
    },
    company_exists: 'Company already exists',
};
export default en;
